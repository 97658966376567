/**
 * Main JS
 */
(function($) {

  let didScroll,
      lastScrollTop = 0,
      delta = 4,
      header = $('header.header'),
      navbarHeight = header.outerHeight();


  /**
   * Add aria labels to buttons in carousels
   */
  function set_carousel_aria_labels () {

    const carousels = $('.owl-carousel');
    if (carousels.length) {
      set_custom_owl_nav_aria();
      set_owl_dots_aria();
    }

  }//end set_carousel_aria_labels


  function set_owl_dots_aria () {

    const owl_dots = $('div.theme-owl-dots-container, div.owl-dots');
    if (owl_dots.length) {
      owl_dots.each(function(){

        let $this = $(this);
        if ($this.find('button').length) {
          let buttons = $this.find('button'),
              counter = 1;
          buttons.each(function(){
            $(this).attr('aria-label', 'Item '+counter);
            counter++;
          })
        }

      });
    }

  }//end set_custom_owl_dots_aria


  function set_custom_owl_nav_aria() {
    if ($('.theme-owl-nav-container, div.owl-nav').length) {
      $('.theme-owl-nav-container, div.owl-nav').each(function () {

        let $this = $(this);
        $this.find('button.owl-next').attr('aria-label', 'Next').attr('role', 'button');
        $this.find('button.owl-prev').attr('aria-label', 'Previous').attr('role', 'button');

      });
    }
  }//end set_custom_owl_nav_aria


  /**
   * Align the headings and content within each post
   */
  function align_carousel_posts () {

    const cards = $('.content-carousel .content-item');
    if (cards.length) {
      let heading_min_h = 0,
          content_min_h = 0;
      cards.each(function(){
        let heading = $(this).find('.heading-wrap'),
            content = $(this).find('.theme-wysiwyg');

        heading.css('height', 'auto');
        content.css('height', 'auto');

        let heading_h = heading.height(),
            content_h = content.height();

        if (parseInt(heading_h) > heading_min_h) {
          heading_min_h = heading_h;
        }

        if (parseInt(content_h) > content_min_h) {
          content_min_h = content_h;
        }

      });

      cards.find('.heading-wrap').height(heading_min_h);
      cards.find('.theme-wysiwyg').height(content_min_h);
    }//end if cards len

  }//end align_carousel_posts


  /**
   * Align the headings and content within each team member item
   */
  function align_team_member_cards () {

    const sections = $('.people-section');
    if (sections.length) {

      sections.each(function(){

        let this_section = $(this),
            cards = this_section.find('div.person');

        if (cards.length) {

          let heading_min_h = 0,
          role_min_h = 0;
          cards.each(function(){
            let heading = $(this).find('.content-wrap h3'),
                role = $(this).find('p.role');

            heading.css('height', 'auto');
            role.css('height', 'auto');

            let heading_h = heading.height(),
                role_h = role.height();

            if (parseInt(heading_h) > heading_min_h) {
              heading_min_h = heading_h;
            }

            if (parseInt(role_h) > role_min_h) {
              role_min_h = role_h;
            }

          });

          cards.find('.content-wrap h3').height(heading_min_h);
          cards.find('p.role').height(role_min_h);

        }//end if cards len

      });

    }//end if cards len

  }//end align_team_member_cards


  /**
   * Check the scroll position and animate the top bar out if scrolled.
   */
  function hasScrolled() {
    let st = $(this).scrollTop();

    if (lastScrollTop > 0) {

      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      //Hide the search if it is showing
      $('div#theme-header-search').removeClass('active');

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > lastScrollTop && st > navbarHeight) {
        // Scroll Down
        header.removeClass('nav-down').addClass('nav-up');
      } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
          header.removeClass('nav-up').addClass('nav-down');
        }
      }

    }//end last scroll > 0


    lastScrollTop = st;
  }//end hasScrolled


  //Toggle tabindex for active owl-items buttons for keyboard nav
  function set_carousel_buttons_tabindex (this_carousel) {

    // Retrieve all owl-item elements within this carousel
    let owl_items = this_carousel.$element.find('.owl-item');

    if (owl_items.length) {
      owl_items.each(function(){

        let $this_item = $(this);
        if ($this_item.hasClass('active')) {
          $this_item.find('.col-carousel-button').attr('tabindex', '0');
        } else {
          $this_item.find('.col-carousel-button').attr('tabindex', '-1');
        }

      });
    }


  }//end set_carousel_cols_buttons_tabindex


  /**
   * Cf7 hooks for terms and newsletter checkboxes
   */
  function init_cf7_hooks () {

    if (!$('.wpcf7').length) {
      return;
    }

    const form_elems = {
      'select' : $('#theme-form-select'),
      'dropdown' : $('input#dropdown-value'),
      'terms_agree' : $('input#theme-form-terms-agree'),
      'cf7_submit_btn' : $('button[type="submit"].wpcf7-submit'),
      'newsletter_agree' : $('input#theme-form-newsletter-subscribe-agree'),
      'newsletter_input' : $('#hidden-newsletter-subscribe'),
    };


    //Set the form dropdown value, and Change the form dropdown value on select change
    if (form_elems.select.length && form_elems.dropdown.length) {
      form_elems.dropdown.val(form_elems.select.val());

      form_elems.select.change(function(){
        form_elems.dropdown.val(form_elems.select.val());
      });
    }//end CF7 dropdown value update


    //Enable the submit button if the terms checkbox has been checked
    if (form_elems.terms_agree.length) {
      form_elems.terms_agree.change(function(){

        let $this = $(this),
            button = $('button[type="submit"].wpcf7-submit'),
            is_checked = $this.prop('checked');

        if (is_checked) {
          button.removeClass('disabled');
        } else {
          button.addClass('disabled');
        }

      });
    }//end CF7 terms checkbox toggle button disabled


    //if user clicks on the disabled CF7 button, focus on terms checkbox
    if (form_elems.cf7_submit_btn.length && form_elems.terms_agree.length) {
      form_elems.cf7_submit_btn.click(function(e){
        if ($(this).hasClass('disabled')) {
          e.preventDefault();
          form_elems.terms_agree.focus();
          return false;
        }
      });
    }//CF7 submit click - do not submit if disabled


    //Toggle newsletter checkbox val:
    if (form_elems.newsletter_agree.length && form_elems.newsletter_input.length) {

      form_elems.newsletter_agree.change(function(){

        let $this = $(this);
        if ($this.prop('checked')) {
          form_elems.newsletter_input.val('Yes');
        } else {
          form_elems.newsletter_input.val('No');
        }

      });

    }//end toggle newsletter checkbox val


    //Fired when form submitted, regardless of errors. Enable the submit button again
    document.querySelector('.wpcf7').addEventListener('wpcf7submit', function (event) {

      //Reset the dropdowns and checkboxes when mail sent successfully
      if (event.detail.apiResponse.status === 'mail_sent') {
        setTimeout(function () {

          if (form_elems.select.length) {
            form_elems.select.prop('selectedIndex', 0);
            $('input#dropdown-value').val('');
          }

          if (form_elems.terms_agree.length) {
            form_elems.terms_agree.prop('checked', false);
          }

          if (form_elems.newsletter_agree.length) {
            form_elems.newsletter_agree.prop('checked', false);
          }

          if (form_elems.cf7_submit_btn.length) {
            form_elems.cf7_submit_btn.addClass('disabled');
          }

        }, 3500);
      }

    }, false);
    //End CF7 submit

  }//end init_cf7_hooks


  /**
   * Get the scroll offset of a section for in page navigation
   * @param target
   * @returns {number}
   */
  function get_section_scroll_to_offset (target) {

    const header = $('header.header');
    let header_h = (header.outerHeight() - $('.top-bar').outerHeight());
    let offset = parseInt($(target).offset().top) - header_h;

    //if nav is hidden, just use the header outer height
    if (header.hasClass('nav-up')) {
      offset = parseInt($(target).offset().top) - header.outerHeight();
    }

    //If we're scrolling up, factor in the header top nav height
    if (offset < lastScrollTop) {
      offset = parseInt($(target).offset().top) - header.outerHeight();
    }

    return offset;

  }//end get_section_scroll_to_offset


  /**
   * Validate the timezone for the countdown shortcode
   * @param time_zone
   * @returns {boolean}
   */
  function is_valid_timezone (time_zone) {

    try {
      new Intl.DateTimeFormat(undefined, {time_zone});
      return true;
    } catch (error) {
      return false;
    }

  }//end is_valid_timezone


  /**
   * Initialise countdown timer shortcodes.
   */
  function init_countdown_timers () {

    let timers = $('p.hsr-countdown-timer');
    if (timers.length) {

      timers.each(function(){

        let $this = $(this),
            end_date = $this.data('end-date'),
            message_before = $this.data('message-before'),
            message_after = $this.data('message-after'),
            timezone = $this.data('timezone'),
            after_countdown = $this.data('after-countdown');

        //Double check the TZ:
        if (!is_valid_timezone(timezone)) {
          return;
        }

        //Parse the end date
        let [day, month, year] = end_date.split('-').map(Number);
        let end_date_obj = new Date(Date.UTC(year, month - 1, day));

        let now = new Date();
        let now_in_time_zone = new Date(now.toLocaleString("en-US", { timezone }));

        // Calculate the difference in time (milliseconds)
        let diff_time = end_date_obj.getTime() - now_in_time_zone.getTime();

        // Convert milliseconds to full days
        let diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24));

        //Diff in days is 0 and no end date message? Then hide
        if (diff_days <= 0 && typeof after_countdown === 'undefined') {
          $this.remove();
        } else if (diff_days <= 0 && after_countdown.length) {
          $this.find('span.message').text(after_countdown);
          $this.removeClass('hidden');
        } else {
          let days_str = (diff_days === 1) ? 'day' : 'days';
          $this.find('span.message').text(message_before+' '+diff_days+' '+days_str+' '+message_after);
          $this.removeClass('hidden');
        }

      });

    }

  }//end init_countdown_timers


  $(function() {


    //Init CF7 hooks
    init_cf7_hooks();


    //Align team member cards:
    align_team_member_cards();


    //Start the countdown timers
    init_countdown_timers();


    //Recalculate and set alignment for cards on window resize
    $(window).on('resize', function(){
      align_team_member_cards();
    });


    //Header top nav scroll detection
    $(window).scroll(function (event) {
      didScroll = true;
    });
    setInterval(function () {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 50);


    //Page scroll
    $("a.page-scroll, a.scroll-to, li.page-scroll.current-menu-item > a").bind("click", function (e) {

      let target = $(this).attr("href");

      if (!target.length || !$(target).length) {
        return false;
      }

      $("html, body").scrollTop(get_section_scroll_to_offset(target));

      e.preventDefault();

    });


    //Content carousel
    if ($('.content-carousel').length) {

      let carousels = $('.content-carousel');

      carousels.each(function(){

        let $this = $(this),
            nav_container = $this.data('nav-container'),
            dots_container = $this.data('dots-container'),
            bg_color = $this.data('bg-color');

        let arrows_color = 'lightblue';
        switch (bg_color) {

          case 'bg-color-white':
          case 'bg-color-pale_blue':
            arrows_color = 'lightblue';
          break;

          case 'bg-color-blue':
          case 'bg-color-med_blue':
            arrows_color = 'white';
          break;

          case 'bg-color-light_blue':
            arrows_color = 'blue';
          break;

          case 'bg-color-light_pink':
            arrows_color = 'pink';
          break;

        }


        $this.owlCarousel({
          items: 3,
          dots: true,
          nav: true,
          navContainer: nav_container,
          dotsContainer: dots_container,
          margin: 30,
          navText: ['<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-left-'+arrows_color+'.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-right-'+arrows_color+'.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          onInitialized: function (e) {
            align_carousel_posts();
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onResized: function () {
            align_carousel_posts();
          },
          onResize: function () {
            align_carousel_posts();
          },
          onRefresh: function () {
            align_carousel_posts();
          },
          onRefreshed: function () {
            align_carousel_posts();
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items: 2,
            },
            1024: {
              items: 3,
            },
            1200: {
              items: 3,
            },
            1366: {
              items: 3
            }
          }
        });

      });

    }//end content carousel


    //Content carousel section collapsed show:
    $('section.content-carousel-section.section-collapsed-closed div.collapse').on('shown.bs.collapse', function(){
      let $this = $(this),
          carousel = $this.find('.content-carousel');

      //Wait for collapse transition to complete
      setTimeout(function () {
        carousel.removeClass('collapsed-hidden-carousel');
      }, 400);

    });


    //Team section - if collapsed, then add opacity class to people items. This is removed on show.
    if ($('section.people-section.section-collapsed-closed').length) {
      $('section.people-section.section-collapsed-closed').find('div.person-outer').addClass('item-hidden');
    }

    //Team section collapse show - set heading height:
    $('section.people-section.section-collapsed-closed div.collapse').on('shown.bs.collapse', function(){
      align_team_member_cards();
      let $this = $(this);
      setTimeout(function () {
        $this.find('div.person-outer').removeClass('item-hidden');
      }, 150);
    });

    //Logos carousel
    if ($('.logos-carousel').length) {

      let carousels = $('.logos-carousel');

      carousels.each(function(){

        let $this = $(this),
            nav_container = $this.data('nav-container'),
            dots_container = $this.data('dots-container'),
            bg_color = $this.data('bg-color');

        let arrows_color = 'lightblue';
        switch (bg_color) {

          case 'bg-color-white':
          case 'bg-color-pale_blue':
            arrows_color = 'lightblue';
          break;

          case 'bg-color-blue':
          case 'bg-color-med_blue':
            arrows_color = 'white';
          break;

          case 'bg-color-light_blue':
            arrows_color = 'blue';
          break;

          case 'bg-color-light_pink':
            arrows_color = 'pink';
          break;

        }

        $this.owlCarousel({
          items: 1,
          dots: true,
          nav: true,
          navContainer: nav_container,
          dotsContainer: dots_container,
          margin: 30,
          navText: ['<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-left-'+arrows_color+'.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-right-'+arrows_color+'.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          onInitialized: function (e) {
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
            0: {
              items: 1,
              margin: 0
            }
          }
        });

      });

    }//end logos carousel


    //Gallery carousel
    if ($('.gallery-carousel').length) {

      let carousels = $('.gallery-carousel'),
          max_items = 3;

      carousels.each(function(){

        let $this = $(this),
            items = $this.data('items-count'),
            nav_container = $this.data('nav-container'),
            dots_container = $this.data('dots-container'),
            margin = $this.data('margin'),
            bg_color = (typeof $this.data('bg-color') !== 'undefined') ? $this.data('bg-color') : 'lightblue';

        let arrows_color = 'lightblue';
        switch (bg_color) {

          case 'bg-color-white':
          case 'bg-color-pale_blue':
            arrows_color = 'lightblue';
          break;

          case 'bg-color-blue':
          case 'bg-color-med_blue':
            arrows_color = 'white';
          break;

          case 'bg-color-light_blue':
            arrows_color = 'blue';
          break;

          case 'bg-color-light_pink':
            arrows_color = 'pink';
          break;

        }

        if (items > max_items) {
          items = max_items;
        }

        $this.owlCarousel({
          items: items,
          dots: true,
          nav: true,
          navContainer: nav_container,
          dotsContainer: dots_container,
          margin: margin,
          navText: ['<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-left-'+arrows_color+'.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-right-'+arrows_color+'.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          onInitialized: function (e) {
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
            0: {
              items: 1,
              margin: 0,
            },
            768: {
              items: 2,
            },
            1024: {
              items: items,
            },
            1200: {
              items: items,
            },
            1366: {
              items: items,
            }
          }
        });

      });

    }//end gallery carousel


    //Mentor / mentee carousel
    if ($('.mentor-mentee-carousel').length) {

      let carousels = $('.mentor-mentee-carousel');

      carousels.each(function(){

        let $this = $(this),
            nav_container = $this.data('nav-container'),
            count_container = $this.data('count-container'),
            dots_container = $this.data('dots-container');

        if (parseInt($this.data('items-count')) <= 1) {
          $(count_container).addClass('d-none');
        }

        $this.owlCarousel({
          items: 1,
          dots: true,
          nav: true,
          navContainer: nav_container,
          dotsContainer: dots_container,
          margin: 0,
          navText: ['<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-left-lightblue.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-right-lightblue.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          onInitialized: function (e) {
            set_carousel_buttons_tabindex(e.relatedTarget);

            //Set the numbers on the dots:
            let count = e.item.count,
                initial = 1;

            $(count_container).html('<p><span class="active">'+initial+'</span> of <span class="count">'+count+'</span></p>');


          },
          onChanged: function(e){

            let current_slide = (e.item.index + 1);
            $(count_container).find('span.active').text(current_slide);

            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
        });

      });

    }//end mentor-mentee-carousel


    //CPT hero gallery:
    if ($('.cpt-gallery').length) {

      $('.cpt-gallery').owlCarousel({
          items: 1,
          dots: true,
          nav: true,
          margin: 0,
          navText: ['<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-left-lightblue.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/HealthSystemsResearch2024/assets/img/icons/chevron-right-lightblue.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          onInitialized: function (e) {
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
              0: {
                nav: false
              },
              768: {
                nav: false
              },
              1024: {
                nav: true
              },
              1200: {
                nav: true
              },
              1366: {
                nav: true
              }
            }
        });

    }//end cpt-gallery len


    //Trigger the team modal:
    $('button.team-member-bio-btn').click(function(){

      const $this = $(this),
            name = $this.data('name'),
            bio = $this.data('bio'),
            img = $this.data('img'),
            socials = $this.data('connect'),
            role = $this.data('role');

      let pronoun = $this.data('pronoun');

      $('div#team-member-modal-img-wrap').removeClass('hidden');
      if (img.length) {
        $('div#team-member-modal-img-wrap').html('<img src="'+img+'" alt="'+name+'">');
      } else {
        $('div#team-member-modal-img-wrap').addClass('hidden');
      }


      if (pronoun.length) {
        pronoun = '<span class="pronoun">('+pronoun+')</span>';
      }


      $('div#heading-content-wrap').html('<h2>'+name+' '+pronoun+'</h2><p class="role">'+role+'</p>'+socials);
      $('#team-member-modal-content-wrap h2.modal-title').text(name+' - '+pronoun);
      $('#team-member-modal-content-wrap div.inner').html(bio);

      $('#team-member-modal').modal('show');

    });


    //Trigger the supporters modal
    $('button.supporter-details-button').click(function(){

      const $this = $(this),
            name = $this.data('name'),
            bio = $this.data('details'),
            socials = $this.data('connect');

      var img = $this.data('img');

      let level = $this.data('supporter-level');
      if (level.length) {
        level = '<p class="supporter-level">'+level+'</p>';
      }

      //No placeholder logo
      if (typeof $this.data('no-logo') !== 'undefined' && $this.data('no-logo') ) {
        img = '';
      }

      $('div#supporter-modal-img-wrap').removeClass('hidden');
      if (img.length) {
        $('div#supporter-modal-img-wrap').html('<img src="'+img+'" alt="'+name+'">');
      } else {
        $('div#supporter-modal-img-wrap').addClass('hidden');
      }

      $('div#heading-content-wrap').html('<h2>'+name+'</h2>'+level+socials);
      $('#supporter-modal-content-wrap h2.modal-title').text(name);
      $('#supporter-modal-content-wrap div.inner').html(bio);

      $('#supporters-modal').modal('show');

    });


    //Stats section select change
    $('select.stats-select').change(function(){

      let $this = $(this),
          id = $this.attr('id'),
          val = $this.val(),
          selected_text = $this.find('option:selected').text(),
          items = $('li[class^="'+id+'-"]'),
          sr_container = $('p.'+id+'-sr');

      let selected_item = $('li.'+id+'-'+val);

      items.removeClass('active').addClass('inactive');
      selected_item.addClass('active').removeClass('inactive');

      //Update the SR element for aria-polite
      let subheading_txt = (selected_item.find('span.subheading').length) ? ' '+selected_item.find('span.subheading').text() : '';
      sr_container.text(selected_text +' '+ selected_item.find('span.stat').text()+subheading_txt);

    });


    //Scroll to open / active tab on accordion
    if ($('section.accordion-section .inner-accordion.accordion').length || $('section.coloured-content-blocks-section .inner-accordion.accordion').length) {
      $('section.accordion-section .inner-accordion.accordion, section.coloured-content-blocks-section .inner-accordion.accordion').each(function () {

        let $this = $(this);
        $this.find('.accordion-collapse').on('shown.bs.collapse', function (e) {
          let $panel = $(this).closest('.accordion-item');
          let header_h = $('header.header').outerHeight() + 20,//plus 20 for panel margin
              top_bar = Math.abs(parseInt($('div.top-bar').css('margin-top')));

          if ($(window).width() <= 991 || $('header.header').hasClass('nav-up')) {
            top_bar = 0;
          }

          header_h += top_bar;
          let scroll_top = (parseInt($panel.offset().top) - header_h);

          $("html, body").scrollTop(scroll_top);

        });

      });
    }//end scroll to active tab in accordion


    //Open / close search
    $('button.search').click(function(){

      const header_search_wrap = $('div#theme-header-search'),
            search_input = $('input#header-search-input'),
            close_btn = $('button.header-search-close'),
            $this = $(this);

      if (header_search_wrap.hasClass('active')) {
        $this.attr('aria-expanded', 'false');
        close_btn.attr('aria-expanded', 'false');
        header_search_wrap.attr('aria-hidden', 'true');
        header_search_wrap.removeClass('active');
        setTimeout(function () {
          header_search_wrap.removeClass('show');
          search_input.blur();
        }, 550);
      } else {
        $this.attr('aria-expanded', 'true');
        close_btn.attr('aria-expanded', 'true');
        header_search_wrap.attr('aria-hidden', 'false');

        header_search_wrap.addClass('show');
        setTimeout(function () {
          header_search_wrap.addClass('active');
          search_input.focus();
        }, 50);
      }

    });//end open search


    //Close search
    $('button.header-search-close').click(function(){
      const header_search_wrap = $('div#theme-header-search');

      header_search_wrap.removeClass('active').attr('aria-hidden', 'true');
      setTimeout(function () {
        header_search_wrap.removeClass('show');
      }, 550);
      $('input#header-search-input').blur();
      $('button.search-btn').attr('aria-expanded', 'false');
      $(this).attr('aria-expanded', 'false');
    });


    //Set carousel aria labels
    set_carousel_aria_labels();


    //Nav button toggles:
    $('#navbarCollapse').on('show.bs.collapse', function () {
      $('body').addClass('ofh');
    });
    $('#navbarCollapse').on('hide.bs.collapse', function () {
      $('body').removeClass('ofh');
    });


    //Video modals
    $('button.video-iframe-loader').click(function(){

      let $this = $(this),
          iframe = $this.data('iframe'),
          holder = $('#video-modal-iframe-holder'),
          container = $('div.iframe-container');

      $('body').addClass('video-modal-open');

      holder.html(iframe);

      $('#video-modal').modal('show');

      //Wait for the iframe to fully load:
      let $iframe = holder.find('.theme-video-iframe');
      $iframe.on('load', function() {
        container.removeClass('loading');
        setTimeout(function () {
          container.addClass('loaded');
        }, 500);
      });

    });


    //Clear the iframe html on closed to prevent the video from playing in the background
    $('#video-modal').on('hidden.bs.modal', function(){

      $('#video-modal-iframe-holder').html('');
      $('body').removeClass('video-modal-open');

    });


  });//end DOM loaded


})(jQuery); // Fully reference jQuery after this point.




























